import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import { Affiliation1 } from './Affiliation';
import { Affiliation2 } from './Affiliation';
import SvgIcon from '../../shared/SvgIcon';
import { validateAndFilterForm } from './validateAndFilterForm';
import { createCareer, editCareer, deleteCareer } from '../../../api/Mycareer/Career';
import DateInput from './DateInput';
import UnknownRadio from './UnknownRadio';
import CareerTypeDropdown, { CareerTypeDropdown2 } from './CareerTypeDropdown';
import ParticipantType from './ParticipantType';
import { Form } from 'react-router-dom';
import moment from 'moment'; // moment 라이브러리 임포트(세연)
import DeletePopup from './DeletePopup';
import { trackEvent } from '../../../utils/ga4';

const AddCareerModal = ({ onClose, mode = 'add', initialData }) => {
	const navigate = useNavigate();
	const currentLocation = useLocation(); // 기존의 `location`과 충돌 방지

	// console.log('initialData:', initialData);

	//카테고리 정보
	const categoryMap = {
		1: '동아리',
		2: '대외활동',
		3: '공모전/대회',
		4: '프로젝트',
		5: '경력',
		6: '교육',
		7: '기타',
	};
	const categoryColors = {
		1: '#FCC400',
		2: '#77AFF2',
		3: '#C48DEF',
		4: '#78D333',
		5: '#FA7C79',
		6: '#F99538',
		7: '#707070',
	};

	// 현재 모달 모드(활동 추가 or 활동 수정)
	const isEditMode = mode === 'edit';

	// 현재 선택된 카테고리 (기본값은 1)
	const [selectedCategory, setSelectedCategory] = useState(1);

	// 폼 에러 상태 (필드명: 에러 메세지)
	const [formErrors, setFormErrors] = useState({});

	useEffect(() => {
		if (initialData?.category) {
			// 한글 카테고리 이름을 숫자 키로 변환
			// const categoryNumber = Object.keys(categoryMap).find(
			// 	(key) => categoryMap[key] === initialData.category.categoryKoName, // 수정된 부분
			// );
			// setSelectedCategory(parseInt(categoryNumber, 10) || 1);
			if (initialData.category.categoryEnName === 'CIRCLE') {
				setSelectedCategory(1);
			} else if (initialData.category.categoryEnName === 'ACTIVITY') {
				setSelectedCategory(2);
			} else if (initialData.category.categoryEnName === 'EDU') {
				setSelectedCategory(6);
			} else if (initialData.category.categoryEnName === 'EMP') {
				setSelectedCategory(5);
			} else if (initialData.category.categoryEnName === 'PROJECT') {
				setSelectedCategory(4);
			} else if (initialData.category.categoryEnName === 'COM') {
				setSelectedCategory(3);
			} else if (initialData.category.categoryEnName === 'ETC') {
				setSelectedCategory(7);
			}
		}
	}, [initialData]);

	//12가지 유형의 form Data 상태관리
	const [name, setName] = useState(''); //활동명
	const [alias, setAlias] = useState(''); //별칭
	const [startdate, setStartdate] = useState(null); //시작일자
	const [enddate, setEnddate] = useState(null); //종료일자
	const [unknown, setUnknown] = useState(false); //종료일자 알 수 없음 여부
	const [location, setLocation] = useState('ON_CAMPUS'); //소속(ON_CAMPUS: 교내, OFF_CAMPUS: 교외, OTHER: 기타)
	const [role, setRole] = useState(''); //역할
	const [organizer, setOrganizer] = useState(''); //주최
	const [type, setType] = useState(''); //경력분류
	const [workplace, setWorkplace] = useState(''); //근무처
	const [position, setPosition] = useState(''); //직급/직위
	const [field, setField] = useState(''); //직무/분야
	const [time, setTime] = useState(0); //교육시간
	// const [participantType, setParticipantType] = useState({}); //인원-팀인원-기여도
	const [isTeam, setIsTeam] = useState(false);
	const [teamSize, setTeamSize] = useState(0);
	const [contribution, setContribution] = useState(0);

	// 삭제 팝업 모달 관리
	const [showDeletePopup, setShowDeletePopup] = useState(false);

	//각 폼 별 상태 모니터링
	useEffect(() => {
		console.log({
			name,
			alias,
			startdate,
			enddate,
			unknown,
			location,
			role,
			organizer,
			type,
			position,
			field,
			time,
			isTeam,
			teamSize,
			contribution,
		});
	}, [
		name,
		alias,
		startdate,
		enddate,
		unknown,
		location,
		role,
		organizer,
		type,
		position,
		field,
		time,
		isTeam,
		teamSize,
		contribution,
	]);

	// 초기 데이터 설정
	useEffect(() => {
		if (initialData) {
			setName(initialData.name || '');
			setAlias(initialData.alias || '');
			setStartdate(initialData.startdate || null);
			setEnddate(initialData.enddate || null);
			setUnknown(initialData.unknown || false);
			setLocation(initialData.location || 'ON_CAMPUS');
			setRole(initialData.role || '');
			setOrganizer(initialData.organizer || '');
			setType(initialData.type || '');
			setWorkplace(initialData.workplace || '');
			setPosition(initialData.position || '');
			setField(initialData.field || '');
			setTime(initialData.time || 0);
			setIsTeam(initialData.isTeam || false);
			setTeamSize(initialData.teamSize || 0);
			setContribution(initialData.contribution || 0);
		}
	}, [initialData]);

	// 기간 설정 관련
	const hasError = !startdate || (!unknown && !enddate);

	// 경력 Dropdown 옵션
	const careerOptions = ['아르바이트', '인턴', '정규직', '계약직', '프리랜서'];
	const [isCareerDropdownOpen, setIsCareerDropdownOpen] = useState(false);

	//Logic: 선택된 카테고리에 따라 상이한 forms 조합을 렌더링합니다.
	const renderFormByCategory = () => {
		switch (selectedCategory) {
			case 1: //동아리
				return (
					<>
						{/* 활동명 */}
						<FormItem spanTwoColumns>
							<label>
								활동명 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									활동의 성격이 잘 드러나도록 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="ex) 광고 기획 연합동아리, 교내 밴드 동아리 등(20자 이내)"
								maxLength={20}
							/>
							{formErrors.name && <ErrorText style={{ top: '105px' }}>{formErrors.name}</ErrorText>}
						</FormItem>

						{/* 별칭 */}
						<FormItem spanTwoColumns>
							<label>
								별칭 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									동아리의 정식 명칭을 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={alias}
								onChange={(e) => setAlias(e.target.value)}
								placeholder="ex) UMC, 멋쟁이사자처럼 등(20자 이내)"
								maxLength={20}
							/>
							{formErrors.alias && <ErrorText style={{ top: '105px' }}>{formErrors.alias}</ErrorText>}
						</FormItem>

						{/* 기간 */}
						<FormItem spanTwoColumns>
							<label>
								기간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
						</FormItem>

						{/* 시작날짜 */}
						<FormItem>
							<DateInput value={startdate} onChange={setStartdate} />
							{formErrors.startdate && <ErrorText style={{ top: '60px' }}>{formErrors.startdate}</ErrorText>}
						</FormItem>
						{/* 종료날짜 */}
						<FormItem>
							<DateInput value={enddate} onChange={setEnddate} disabled={unknown} />
							<UnknownRadio isUnknown={unknown} onToggle={() => setUnknown(!unknown)} />
							{formErrors.enddate && <ErrorText style={{ top: '60px' }}>{formErrors.enddate}</ErrorText>}
							{hasError && <ErrorText style={{ top: '70px' }}>{formErrors.invalidPeriodError}</ErrorText>}
						</FormItem>

						{/* 소속 */}
						<FormItem>
							<label style={{ marginBottom: '5px' }}>
								소속 <span style={{ color: '#FC5555' }}>*</span>
							</label>
							<Affiliation1
								value={location}
								onAffiliationChange={(newLocation) => {
									setLocation(newLocation);
								}}
							/>
							{formErrors.location && <ErrorText>{formErrors.location}</ErrorText>}
						</FormItem>

						{/* 역할 */}
						<FormItem>
							<label style={{ marginBottom: '5px' }}>역할</label>
							<input
								type="text"
								value={role}
								onChange={(e) => setRole(e.target.value)}
								placeholder="ex) 동아리장, 부원 등"
								maxLength={15}></input>
						</FormItem>
					</>
				);
			case 2: //대외활동
				return (
					<>
						{/* 활동명 */}
						<FormItem spanTwoColumns>
							<label>
								활동명 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									활동의 성격이 잘 드러나도록 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="ex) 00은행 홍보대사, 00공업 서포터즈 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.name && <ErrorText style={{ top: '105px' }}>{formErrors.name}</ErrorText>}
						</FormItem>

						{/* 별칭 */}
						<FormItem spanTwoColumns>
							<label>
								별칭 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									대외활동의 정식 명칭을 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={alias}
								onChange={(e) => setAlias(e.target.value)}
								placeholder="ex) 00손해보험 대학생 서포터즈, 2025년 00부 정책기자단 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.alias && <ErrorText style={{ top: '105px' }}>{formErrors.alias}</ErrorText>}
						</FormItem>

						{/* 기간 */}
						<FormItem spanTwoColumns>
							<label>
								기간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
						</FormItem>

						{/* 시작날짜 */}
						<FormItem>
							<DateInput value={startdate} onChange={setStartdate} />
							{formErrors.startdate && <ErrorText style={{ top: '60px' }}>{formErrors.startdate}</ErrorText>}
						</FormItem>
						{/* 종료날짜 */}
						<FormItem>
							<DateInput value={enddate} onChange={setEnddate} disabled={unknown} />
							<UnknownRadio isUnknown={unknown} onToggle={() => setUnknown(!unknown)} />
							{formErrors.enddate && <ErrorText style={{ top: '60px' }}>{formErrors.enddate}</ErrorText>}
							{hasError && <ErrorText style={{ top: '70px' }}>{formErrors.invalidPeriodError}</ErrorText>}
						</FormItem>

						{/* 주최 */}
						<FormItem>
							<label>
								주최 <span style={{ color: '#FC5555' }}>*</span>
							</label>
							<input
								type="text"
								value={organizer}
								onChange={(e) => setOrganizer(e.target.value)}
								placeholder="ex) 00여행사, 00도청 등"
								maxLength={15}></input>
							{formErrors.organizer && <ErrorText style={{ top: '105px' }}>{formErrors.organizer}</ErrorText>}
						</FormItem>

						{/* 역할 */}
						<FormItem>
							<label>역할</label>
							<input
								type="text"
								value={role}
								onChange={(e) => setRole(e.target.value)}
								placeholder="ex) 팀장, 마케팅 팀원 등"
								maxLength={15}></input>
						</FormItem>

						{/* 개인-팀 */}
						<FormItem spanTwoColumns>
							<ParticipantType
								isTeam={isTeam}
								setIsTeam={setIsTeam}
								teamSize={teamSize}
								setTeamSize={setTeamSize}
								contribution={contribution}
								setContribution={setContribution}
							/>
						</FormItem>
					</>
				);
			case 3: //공모전/대회
				return (
					<>
						{/* 활동명 */}
						<FormItem spanTwoColumns>
							<label>
								활동명 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									활동의 성격이 잘 드러나도록 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="ex) PR 아이디어 공모전, 모의투자대회, 해커톤 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.name && <ErrorText style={{ top: '105px' }}>{formErrors.name}</ErrorText>}
						</FormItem>

						{/* 별칭 */}
						<FormItem spanTwoColumns>
							<label>
								별칭 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									공모전/대회의 정식 명칭을 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={alias}
								onChange={(e) => setAlias(e.target.value)}
								placeholder="ex) 2025 로레0 브랜드스톰 공모전, 기0 PBV 아이디어 공모전 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.alias && <ErrorText style={{ top: '105px' }}>{formErrors.alias}</ErrorText>}
						</FormItem>

						{/* 기간 */}
						<FormItem spanTwoColumns>
							<label>
								기간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
						</FormItem>

						{/* 시작날짜 */}
						<FormItem>
							<DateInput value={startdate} onChange={setStartdate} />
							{formErrors.startdate && <ErrorText style={{ top: '60px' }}>{formErrors.startdate}</ErrorText>}
						</FormItem>
						{/* 종료날짜 */}
						<FormItem>
							<DateInput value={enddate} onChange={setEnddate} disabled={unknown} />
							<UnknownRadio isUnknown={unknown} onToggle={() => setUnknown(!unknown)} />
							{formErrors.enddate && <ErrorText style={{ top: '60px' }}>{formErrors.enddate}</ErrorText>}
							{hasError && <ErrorText style={{ top: '70px' }}>{formErrors.invalidPeriodError}</ErrorText>}
						</FormItem>

						{/* 주최 */}
						<FormItem spanTwoColumns>
							<label>
								주최 <span style={{ color: '#FC5555' }}>*</span>
							</label>
							<input
								type="text"
								value={organizer}
								onChange={(e) => setOrganizer(e.target.value)}
								placeholder="ex) 00문화재단, 00증권 등"
								maxLength={15}></input>
							{formErrors.organizer && <ErrorText style={{ top: '105px' }}>{formErrors.organizer}</ErrorText>}
						</FormItem>

						{/* 개인-팀 */}
						<FormItem spanTwoColumns>
							<ParticipantType
								isTeam={isTeam}
								setIsTeam={setIsTeam}
								teamSize={teamSize}
								setTeamSize={setTeamSize}
								contribution={contribution}
								setContribution={setContribution}
							/>
						</FormItem>
					</>
				);
			case 4: //프로젝트
				return (
					<>
						{/* 활동명 */}
						<FormItem spanTwoColumns>
							<label>
								활동명 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									활동의 성격이 잘 드러나도록 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="ex) 앱 개발 프로젝트, 시각디자인과 졸업 전시 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.name && <ErrorText style={{ top: '105px' }}>{formErrors.name}</ErrorText>}
						</FormItem>

						{/* 별칭 */}
						<FormItem spanTwoColumns>
							<label>
								별칭 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									프로젝트의 이름을 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={alias}
								onChange={(e) => setAlias(e.target.value)}
								placeholder="ex) 끼적, DIMIVERSE 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.alias && <ErrorText style={{ top: '105px' }}>{formErrors.alias}</ErrorText>}
						</FormItem>

						{/* 기간 */}
						<FormItem spanTwoColumns>
							<label>
								기간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
						</FormItem>

						{/* 시작날짜 */}
						<FormItem>
							<DateInput value={startdate} onChange={setStartdate} />
							{formErrors.startdate && <ErrorText style={{ top: '60px' }}>{formErrors.startdate}</ErrorText>}
						</FormItem>
						{/* 종료날짜 */}
						<FormItem>
							<DateInput value={enddate} onChange={setEnddate} disabled={unknown} />
							<UnknownRadio isUnknown={unknown} onToggle={() => setUnknown(!unknown)} />
							{formErrors.enddate && <ErrorText style={{ top: '60px' }}>{formErrors.enddate}</ErrorText>}
							{hasError && <ErrorText style={{ top: '70px' }}>{formErrors.invalidPeriodError}</ErrorText>}
						</FormItem>

						{/* 소속 */}
						<FormItem spanTwoColumns>
							<label style={{ marginBottom: '5px' }}>
								소속 <span style={{ color: '#FC5555' }}>*</span>
							</label>
							<Affiliation2
								onAffiliationChange={(newLocation) => {
									setLocation(newLocation);
								}}
							/>
							{formErrors.location && <ErrorText style={{ top: '105px' }}>{formErrors.location}</ErrorText>}
						</FormItem>

						{/* 개인-팀 */}
						<FormItem spanTwoColumns>
							<ParticipantType
								isTeam={isTeam}
								setIsTeam={setIsTeam}
								teamSize={teamSize}
								setTeamSize={setTeamSize}
								contribution={contribution}
								setContribution={setContribution}
							/>
						</FormItem>
					</>
				);
			case 5: //경력
				return (
					<>
						{/* 분류 */}
						<FormItem>
							<label>
								분류 <span style={{ color: '#FC5555' }}>*</span>
							</label>
							{/* <CareerTypeDropdown onChange={(e)=>setCareerType(e.target.value)}></CareerTypeDropdown> */}
							<CareerTypeDropdown2
								options={careerOptions}
								placeholder="경력 구분을 선택해주세요."
								value={type}
								onChange={(type) => setType(type)}
								isOpen={isCareerDropdownOpen}
								onToggle={() => setIsCareerDropdownOpen(!isCareerDropdownOpen)}
							/>
							{formErrors.type && <ErrorText style={{ top: '105px' }}>{formErrors.type}</ErrorText>}
						</FormItem>

						{/* 근무처 */}
						<FormItem>
							<label>
								근무처 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									근무한 곳의 이름을 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={alias}
								onChange={(e) => setAlias(e.target.value)}
								placeholder="근무처 입력"></input>
							{formErrors.alias && <ErrorText style={{ top: '105px' }}>근무처를 입력해주세요.</ErrorText>}
						</FormItem>

						{/* 활동명 */}
						<FormItem spanTwoColumns>
							<label>
								활동명 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									경력의 성격이 잘 드러나도록 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="ex) 학원 채점 아르바이트, 콘텐츠 에디터 인턴 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.name && <ErrorText style={{ top: '105px' }}>{formErrors.name}</ErrorText>}
						</FormItem>

						{/* 기간 */}
						<FormItem spanTwoColumns>
							<label>
								기간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
						</FormItem>

						{/* 시작날짜 */}
						<FormItem>
							<DateInput value={startdate} onChange={setStartdate} />
							{formErrors.startdate && <ErrorText style={{ top: '60px' }}>{formErrors.startdate}</ErrorText>}
						</FormItem>
						{/* 종료날짜 */}
						<FormItem>
							<DateInput value={enddate} onChange={setEnddate} disabled={unknown} />
							<UnknownRadio isUnknown={unknown} onToggle={() => setUnknown(!unknown)} />
							{formErrors.enddate && <ErrorText style={{ top: '60px' }}>{formErrors.enddate}</ErrorText>}
							{hasError && <ErrorText style={{ top: '70px' }}>{formErrors.invalidPeriodError}</ErrorText>}
						</FormItem>

						{/* 직급/직위 */}
						<FormItem>
							<label>직급/직위</label>
							<input
								type="text"
								value={position}
								onChange={(e) => setPosition(e.target.value)}
								placeholder="ex) 인턴, 보조강사 등"
								maxLength={15}></input>
						</FormItem>

						{/* 직무/분야 */}
						<FormItem>
							<label>직무/분야</label>
							<input
								type="text"
								value={field}
								onChange={(e) => setField(e.target.value)}
								placeholder="ex) 서비스업, iOS 개발 등"
								maxLength={15}></input>
						</FormItem>
					</>
				);
			case 6: //교육
				return (
					<>
						{/* 활동명 */}
						<FormItem spanTwoColumns>
							<label>
								활동명 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									활동의 성격이 잘 드러나도록 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="ex) 데이터 분석 세미나, JAVA 개발 부트캠프 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.name && <ErrorText style={{ top: '105px' }}>{formErrors.name}</ErrorText>}
						</FormItem>

						{/* 별칭 */}
						<FormItem spanTwoColumns>
							<label>
								별칭 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									교육의 정식 명칭을 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={alias}
								onChange={(e) => setAlias(e.target.value)}
								placeholder="ex) 000톤 정글 8기, 00시스템 BEYOND SW 캠프 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.alias && <ErrorText style={{ top: '105px' }}>{formErrors.alias}</ErrorText>}
						</FormItem>

						{/* 기간 */}
						<FormItem spanTwoColumns>
							<label>
								기간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
						</FormItem>

						{/* 시작날짜 */}
						<FormItem>
							<DateInput value={startdate} onChange={setStartdate} />
							{formErrors.startdate && <ErrorText style={{ top: '60px' }}>{formErrors.startdate}</ErrorText>}
						</FormItem>
						{/* 종료날짜 */}
						<FormItem>
							<DateInput value={enddate} onChange={setEnddate} disabled={unknown} />
							<UnknownRadio isUnknown={unknown} onToggle={() => setUnknown(!unknown)} />
							{formErrors.enddate && <ErrorText style={{ top: '60px' }}>{formErrors.enddate}</ErrorText>}
							{hasError && <ErrorText style={{ top: '70px' }}>{formErrors.invalidPeriodError}</ErrorText>}
						</FormItem>

						{/* 주최 */}
						<FormItem>
							<label>
								주최 <span style={{ color: '#FC5555' }}>*</span>
							</label>
							<input
								type="text"
								value={organizer}
								onChange={(e) => setOrganizer(e.target.value)}
								placeholder="ex) 대한상공회의소, 코드0 등"
								maxLength={15}></input>
							{formErrors.organizer && <ErrorText style={{ top: '105px' }}>{formErrors.organizer}</ErrorText>}
						</FormItem>

						{/* 교육 시간 */}
						<FormItem>
							<label>
								교육 시간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
							<input
								type="text"
								value={time}
								onChange={(e) => {
									const value = e.target.value;
									if (/^\d*$/.test(value) && value.length <= 4) {
										setTime(value);
									}
								}}
							/>
						</FormItem>
					</>
				);
			case 7: //기타
				return (
					<>
						{/* 활동명 */}
						<FormItem spanTwoColumns>
							<label>
								활동명 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									활동의 성격이 잘 드러나도록 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								placeholder="ex) 필리핀 해외봉사, 캐나다 교환학생 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.name && <ErrorText style={{ top: '105px' }}>{formErrors.name}</ErrorText>}
						</FormItem>

						{/* 별칭 */}
						<FormItem spanTwoColumns>
							<label>
								별칭 <span style={{ color: '#FC5555' }}>*</span>
								<span style={{ color: '#3AAF85', fontSize: '14px', marginLeft: '10px' }}>
									활동의 정식 명칭을 작성해주세요.
								</span>
							</label>
							<input
								type="text"
								value={alias}
								onChange={(e) => setAlias(e.target.value)}
								placeholder="ex) 000톤 정글 8기, 00시스템 BEYOND SW 캠프 등(20자 이내)"
								maxLength={20}></input>
							{formErrors.alias && <ErrorText style={{ top: '105px' }}>{formErrors.alias}</ErrorText>}
						</FormItem>

						{/* 기간 */}
						<FormItem spanTwoColumns>
							<label>
								기간 <span style={{ color: '#FC5555' }}>*</span>
							</label>
						</FormItem>

						{/* 시작날짜 */}
						<FormItem>
							<DateInput value={startdate} onChange={setStartdate} />
							{formErrors.startdate && <ErrorText style={{ top: '60px' }}>{formErrors.startdate}</ErrorText>}
						</FormItem>
						{/* 종료날짜 */}
						<FormItem>
							<DateInput value={enddate} onChange={setEnddate} disabled={unknown} />
							<UnknownRadio isUnknown={unknown} onToggle={() => setUnknown(!unknown)} />
							{formErrors.enddate && <ErrorText style={{ top: '60px' }}>{formErrors.enddate}</ErrorText>}
							{hasError && <ErrorText style={{ top: '70px' }}>{formErrors.invalidPeriodError}</ErrorText>}
						</FormItem>
					</>
				);
			default:
				return null;
		}
	};

	// GA4
	const trackCategoryEvent = (category) => {
		if (!isEditMode) {
		switch(category) {
			case 1 : 
			case 2:
			case 7:
				//활동 및 경험(동아리, 활동, 기타)
				trackEvent('add_confirm', {
					category:'resume',
					detail: 'add_activitiesAndExperiences',
					action_type:'confirm',
					label: '확인',
				});
				break;
			case 3: 
			case 4:
				//공모전/대회, 프로젝트
				trackEvent('add_confirm', {
					category:'resume',
					detail: 'add_project',
					action_type:'confirm',
					label: '확인',
				});
				break;
			case 5: //경력
				trackEvent('add_confirm', {
					category:'resume',
					detail: 'add_career',
					action_type:'confirm',
					label: '확인',
				});
				break;
			case 6: //교육
				trackEvent('add_confirm', {
					category:'resume',
					detail: 'add_training',
					action_type:'confirm',
					label: '확인',
				});
				break;
			default:
				return;
		}} else {
			switch(category) {
				case 1 : 
				case 2:
				case 7:
					//활동 및 경험(동아리, 활동, 기타)
					trackEvent('edit_click', {
						category:'resume',
						detail: 'edit_activitiesAndExperiences',
						action_type:'edit',
						label: '활동 수정하기',
					});
					break;
				case 3: 
				case 4:
					//공모전/대회, 프로젝트
					trackEvent('edit_click', {
						category:'resume',
						detail: 'edit_project',
						action_type:'edit',
						label: '활동 수정하기',
					});
					break;
				case 5: //경력
					trackEvent('edit_click', {
						category:'resume',
						detail: 'edit_career',
						action_type:'edit',
						label: '활동 수정하기',
					});
					break;
				case 6: //교육
					trackEvent('edit_click', {
						category:'resume',
						detail: 'edit_training',
						action_type:'edit',
						label: '활동 수정하기',
					});
					break;
				default:
					return;
			}
		}	
	};

	// 활동 추가 함수
	const handleAddCareer = async () => {
		if (!isEditMode) {
			trackEvent('add_confirm', {
				category: 'mycareer',
				detail: 'add_career',
				action_type: 'confirm',
				label: '확인',
			});
		}
		// 날짜 입력 유효성 검증
		// if (hasError) {
		// 	setFormErrors((prev) => ({ ...prev, startdate: !startdate ? "시작 날짜를 선택해주세요." : prev.startdate,
		// 		enddate: (!unknown && !enddate) ? "종료 날짜를 선택해주세요." : prev.enddate }));
		// 	return;
		// }

		// startdate와 enddate를 YYYY-MM-DD 형식으로 변환하기 위해 추가 (에러)
		const formattedStartdate = startdate !== '' && startdate != null ? moment(startdate).format('YYYY-MM-DD') : null;
		const formattedEnddate = unknown || enddate === '' || enddate == null ? null : moment(enddate).format('YYYY-MM-DD');

		const allFormData = {
			name,
			alias,
			startdate: formattedStartdate,
			enddate: formattedEnddate,
			unknown,
			location,
			role,
			organizer,
			type,
			position,
			field,
			time,
			isTeam,
			teamSize,
			contribution,
		};
		console.log('Sending data:', allFormData);

		// 날짜 외 입력 데이터 검증 및 필터링 실행
		// 잘 수행되면 isValid:true와 filteredDate를, 오류가 있으면 isValid:false와 errors를 반환
		const { isValid, errors, filteredData } = validateAndFilterForm(selectedCategory, allFormData);
		console.log('errors: ', errors);

		//오류 생길 경우
		if (!isValid) {
			// errors 배열을 필드별 에러 객체로 변환
			let errorsObj = {};
			errors.forEach((err) => {
				if (err === '활동명을 입력해주세요.') {
					errorsObj.name = err;
				} else if (err === '별칭을 입력해주세요.') {
					errorsObj.alias = err;
				} else if (err === '시작 날짜를 선택해주세요.') {
					errorsObj.startdate = err;
				} else if (err === '종료 날짜를 선택해주세요.' || err === '종료 날짜는 시작 날짜 이후로 설정해주세요') {
					errorsObj.enddate = err;
				} else if (err === '종료 날짜는 시작 날짜 이후로 설정해주세요') {
					errorsObj.invalidPeriodError = err;
				} else if (err === '소속을 선택해주세요.') {
					errorsObj.location = err;
				} else if (err === '주최를 입력해주세요.') {
					errorsObj.organizer = err;
				} else if (err === '인원을 선택해주세요.') {
					errorsObj.teamSize = err;
				} else if (err === '분류를 선택해주세요.') {
					errorsObj.type = err;
				} else {
					errorsObj.general = err;
				}
			});
			setFormErrors(errorsObj);
			return;
		} else {
			setFormErrors({});
		}

		// api 호출
		if (mode === 'edit') {
			// 수정모드일 경우
			try {
				// 수정 모드에서는 id를 추가해줍니다.
				const careerId = initialData.id;
				console.log('Sending data:', filteredData);
				const response = await editCareer(selectedCategory, careerId, filteredData);
				console.log('Success - 활동 수정: ', response);
				// onClose();
				window.location.reload();
			} catch (error) {
				console.error('수정모드에서 id 추가 중 오류 발생: ', error);
			}
		} else {
			// 추가모드일 경우
			try {
				console.log('Sending data:', filteredData);
				const response = await createCareer(selectedCategory, filteredData);
				console.log('Success - 활동 추가: ', response);
				// onClose();
				//window.location.reload();
				//navigate('/mycareer'); //세연 추가
				// 현재 경로가 `/mycareer`라면 새로고침, `/home`이라면 `/mycareer`로 이동
				if (currentLocation.pathname === '/mycareer' || currentLocation.pathname === '/history') {
					setTimeout(() => {
						window.location.reload();
					}, 100); // 100ms 후 실행 (리액트 상태 업데이트 이후 확실하게 새로고침)
				} else if (currentLocation.pathname === '/home') {
					navigate('/mycareer');
				}

				onClose();
			} catch (error) {
				console.error('createCareer 호출 중 오류 발생: ', error.response ? error.response.data : error.message);
			}
		}

		onClose();
		trackCategoryEvent(selectedCategory);
	};

	// 활동 삭제 함수
	const handleDeleteCareer = async () => {
		if (mode === 'edit') {
			try {
				const careerId = initialData.id;
				const response = await deleteCareer(selectedCategory, careerId);
				console.log('Success - 활동 삭제: ', response);
				// onClose();
				// navigate('/mycareer');
				// 현재 경로가 '/history'가 아니라면 '/mycareer'로 이동
                if (currentLocation.pathname !== '/history') {
					console.log('loaction.pathname: ', currentLocation.pathname);
                    navigate('/mycareer');
                } else {
                    window.location.reload();
                }
			} catch (error) {
				console.error('deleteCareer 호출 중 오류 발생: ', error.response ? error.response.data : error.message);
			}
		}
		onClose();
	};

	return (
		<ModalBackground>
			{/* 삭제 팝업 */}
			{showDeletePopup && (
				<DeletePopup
					onClose={() => setShowDeletePopup(false)}
					onConfirm={() => {
						handleDeleteCareer();
						setShowDeletePopup(false);
					}}
				/>
			)}
			<ModalContainer>
				<CloseButton onClick={onClose}>
					<SvgIcon name="close" size={20} color="#999" />
				</CloseButton>
				<h1 style={{ textAlign: 'center' }}>{isEditMode ? '활동 수정' : '활동 추가'}</h1>
				<ButtonContainer>
					{Object.keys(categoryMap).map((key) => (
						<CategoryButton
							type="button"
							key={key}
							isSelected={selectedCategory === parseInt(key)}
							bgColor={categoryColors[key]}
							onClick={() => {
								if (!isEditMode) {
									// 수정모드가 아닐 때만 카테고리 변경 가능
									setSelectedCategory(parseInt(key));
								}
							}}>
							{categoryMap[key]}
						</CategoryButton>
					))}
				</ButtonContainer>
				<div style={{ height: '18px' }} />
				<ModalForm>{renderFormByCategory()}</ModalForm>
				{isEditMode ? (
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
						<DeleteButton type="button" onClick={() => setShowDeletePopup(true)}>
							삭제
						</DeleteButton>
						<SaveButton type="button" onClick={handleAddCareer} style={{ width: '425px' }}>
							저장
						</SaveButton>
					</div>
				) : (
					<SaveButton type="button" onClick={handleAddCareer}>
						저장
					</SaveButton>
				)}
			</ModalContainer>
		</ModalBackground>
	);
};

export default AddCareerModal;

// Modal 전체 레이아웃
const ModalForm = styled.form`
	border-radius: 10px;
	background: #fff;
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* 두 개의 열 */
	grid-gap: 20px; /* 요소들 간의 간격 */
	column-gap: 50px;
	width: 100%;

	// 스크롤바
	max-height: 400px;
	overflow-y: scroll;
	overflow-x: hidden;

	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none; /* Chrome, Safari, Opera */
	}
`;

// 전체 너비를 차지하는 폼 요소 (활동명, 별칭 등)
const FullWidthInput = styled.div`
	grid-column: span 2; /* 열 두 개를 차지 */
`;

// 개별 폼 요소 스타일
const FormItem = styled.div`
	display: flex;
	flex-direction: column;
	grid-column: ${(props) => (props.spanTwoColumns ? 'span 2' : 'span 1')}; /* 열을 조건부로 설정 */
	// width: 560px;
	position: relative;

	label {
		margin-bottom: 8px;
		margin-top: 22px;
		font-size: 18px;
		// font-weight: 400;
		font-family: 'SemiBold';
	}
	input {
		height: 30px;
		padding: 10px;
		font-size: 16px;
		font-family: 'Regular';
		border: none;
		border-radius: 10px;
		background: #f5f5f5;
		// width: 100%;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	gap: 10px; /* 버튼들 간의 간격 설정 */
	justify-content: space-between; /* 버튼을 가운데 정렬 */
	flex-wrap: wrap; /* 버튼들이 화면에 맞지 않을 경우 줄바꿈 처리 */
`;

const CategoryButton = styled.button`
	display: flex;
	height: 35px;
	padding: 6px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	border-radius: 10px;
	font-size: 14px;
	font-family: 'Regular';
	cursor: pointer;
	border: none;

	background-color: ${(props) => (props.isSelected ? props.bgColor : '#F5F5F5')};
	color: ${(props) => (props.isSelected ? '#FFF' : '#707070')};

	&:focus {
		outline: none;
	}
`;
const ModalContainer = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 580px;
	max-width: 90%;
	background-color: #fff;
	border-radius: 10px;
	padding: 50px 120px;
	z-index: 1000;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 768px) {
		width: 100%;
		max-height: 90%;
		overflow-y: auto;
	}
`;

const ModalBackground = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
`;

const SaveButton = styled.button`
	width: 580px;
	height: 50px;
	background-color: #3aaf85;
	border: none;
	border-radius: 10px;
	color: white;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	font-size: 18px;
	font-family: 'Regular';
`;

const DeleteButton = styled.button`
	width: 140px;
	height: 50px;
	background-color: #fff;
	border-radius: 10px;
	border: 1.5px solid var(--sub-rd, #fa7c79);
	color: #fa7c79;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-right: 15px;
	font-size: 18px;
	font-family: 'Regular';
`;

const CloseButton = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;
	background: none;
	border: none;
	font-size: 24px;
	font-weight: bold;
	cursor: pointer;
	color: #999999;
`;

const ErrorText = styled.div`
	position: absolute;
	top: 80px;
	font-family: 'Regular';
	font-size: 13px;
	color: #ff7979;
	margin-left: 10px;
`;
