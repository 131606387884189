import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import api from '../../Axios';
import { getRecruitDetails } from '../../api/Apply/RecruitDetails'; // API 호출을 위해 import

const CalendarBackgroundSection = styled.div`
  width: 100vw; 
  background-color: #f0f0f0;
  margin-top: 20px;
   min-height: 110vh;
  position: relative;
  padding: 20px 0;
  box-sizing: border-box;
  display: flex;  // Flexbox 적용
  justify-content: center;
  align-items: flex-start;  // 상단 정렬 (필요하면 center로 변경)
`;


const CalendarAdListStyled = styled.div`
  padding: 20px;
  border-radius: 10px;
  margin-top: 30px;
  margin-left: -45px;
  max-width: 820px;
  width: 100%;  // 가로 너비 100% 설정
  flex-direction: column;  // 수직 정렬
  align-items: center;  // 내부 요소 가운데 정렬

 @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 350px;
    justify-content: center;
	  align-items: center;
  }
    @media (max-width: 350px) {
    width: 320px;
    justify-content: center;
	align-items: center;
  }
`;
const CalendarAdDate = styled.div`
  font-size: 14px;
  color: var(--black, #000);
  font-family: Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
   margin-left: -680px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  position: absolute;  // 리스트 위로 고정
  top: 30px;  // 리스트 위로 위치 조정  

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-left: 0px;
  }
`;


const CalendarAdDateSection = styled.div`
	margin-bottom: 50px;
`;

const CalendarAdItem = styled.div`
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 10px;
	box-shadow: 1px 1px 6px 0px rgba(112, 112, 112, 0.25);
	cursor: pointer;
	margin-left: 38px;
`;

const CalendarAdDetails = styled.div`
	color: #555;
`;

const CalendarAdTitleContainer = styled.div`
	display: flex;
	align-items: center;
	margin-left: 16px;

   @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
	margin-left: 10px;

	}
`;

const CalendarAdTitle = styled.div`
	color: var(--black, #000);
	font-family: Pretendard;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin-top: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
   font-size: 16px;
  }
`;

const CalendarTagContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 13px;
	margin-left: 15px;
	margin-bottom: 5px;
`;

const CalendarTag = styled.span`
	background: #f5f5f5;
	border-radius: 10px;
	padding: 4px 8px;
	font-size: 13px;
	color: #707070;
	font-family: Light;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
   font-size: 12px;
  }
`;

const ReviewTag = styled.span`
  background: ${({ status }) => {
	if (status === 'UNAPPLIED') return '#D9D9D9';
    if (status === 'PLANNED') return '#B0B0B0';
    if (status === 'APPLYING') return '#707070';
    if (status === 'ACCEPTED') return '#78D333';
    if (status === 'REJECTED') return '#FA7C79';
    return '#D9D9D9';
  }};
  border-radius: 10px;
  padding: 4px 8px;
  color: var(--white, #FFF);
  text-align: center;
  font-family: Light;
  font-size: 12px;
  font-weight: 400;
  margin-right: 8px;
`;


const CalendarStatusCircle = styled.span`
  display: inline-block;
   aspect-ratio: 1;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ status }) => {
    if (status === 'UNAPPLIED') return '#D9D9D9';
    if (status === 'PLANNED') return '#B0B0B0';
    if (status === 'APPLYING') return '#707070';
    if (status === 'ACCEPTED') return '#78D333';
    if (status === 'REJECTED') return '#FA7C79';
    return '#707070';
  }};
  margin-right: 10px;
  margin-top: 5px;

   @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
	width: 12px;
  height: 12px;
	}
`;

const CalendarListView = ({ date, data, count, onJobClick }) => {
  const navigate = useNavigate();

  if (count === 0) {
    return (
      <CalendarBackgroundSection>
      <p style={{ textAlign: 'center', color: '#707070', fontSize: '16px' }}>
      선택하신 날짜가 마감일인 공고가 없습니다.
      </p>
      </CalendarBackgroundSection>
    );
  }

  const handleJobClick = async (ad) => {
    console.log('Selected ad:', ad);
    try {
      const response = await api.get(`/recruit/${ad.recruitId}`);
      const fullAdDetails = { 
          ...response.data, 
          id: ad.recruitId, 
          introduceId: response.data.introduceId ?? 0 //  introduceId 추가 (없으면 0 설정)
      };

      console.log('Full ad details with introduceId:', fullAdDetails);
      navigate(`/apply-detail/${ad.recruitId}`, { state: { job: fullAdDetails } });
    } catch (error) {
      console.error('Failed to fetch recruit details:', error);
    }
};


  return (
    <CalendarBackgroundSection>
     
      <CalendarAdDate>{new Date(date).toISOString().split('T')[0]}</CalendarAdDate>
        <CalendarAdListStyled>
          {data.map((ad, idx) => (
            <CalendarAdItem key={idx} onClick={() => handleJobClick(ad)}>
              <CalendarTagContainer>
			  {ad.reviewTag && <ReviewTag status={ad.status}>{ad.reviewTag}</ReviewTag>}
                {(ad.tag || ad.tags || []).map((tag, tagIdx) => (
                  <CalendarTag key={tagIdx}>{tag}</CalendarTag>
                ))}
              </CalendarTagContainer>
              <CalendarAdDetails>
                <CalendarAdTitleContainer>
                  <CalendarStatusCircle status={ad.status} />
                  <CalendarAdTitle>{ad.title}</CalendarAdTitle>
                </CalendarAdTitleContainer>
              </CalendarAdDetails>
            </CalendarAdItem>
          ))}
        </CalendarAdListStyled>

    </CalendarBackgroundSection>
  );
};

export default CalendarListView;