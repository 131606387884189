import api from "../../Axios";
import axios from "axios";

// presigned URL 생성
const createPresignedUrl = async (data) => {
    console.log('Creating presigned URL:', data);
    try{
        const fileTitle = data.fileTitle;
        const response = await api.get(`/history/file?fileName=${fileTitle}`);
        const { keyName, signedURL } = response.data.data;

        console.log("Success - createPresignedUrl: ", response);

        return { keyName, signedURL };
    } catch (error) {
        console.error("Error creating presigned URL: ", error);
        if(error.response){
            console.error('Server responded with status code:', error.response.status);
            console.error('Server responded with:', error.response.data);
        } else if(error.request){
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
        throw error; //saveKeyName에서 에러 처리 받기 위함
    }
};

// s3에 파일 업로드 
const uploadFileToS3 = async (file, presignedURL) => {
    console.log('Uploading file to S3:', file, presignedURL);
    try{
        // const response = await axios.put(presignedURL, file, {
        //     headers: {
        //         'Content-Type': file.type,
        //         'x-amz-server-side-encryption' : 'AES256'
        //     }
        // });
        const response = await fetch(presignedURL, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': file.type,
                'x-amz-server-side-encryption' : 'AES256',
                // 'origin': 'https://test.kkijuk.com'
            }
        })
        // console.log("Success - uploadFileToS3: ", response.data);

        if (!response.ok) {
            throw new Error(`Failed to upload. status: ${response.status}`);
        }

        console.log("Success - uploadFileToS3: ", response);
        return response.data;
    } catch (error) {
        console.error("Error uploading file to S3: ", error);
        if(error.response){
            console.error('Server responded with status code:', error.response.status);
            console.error('Server responded with:', error.response.data);
        } else if(error.request){
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
        throw error;
    }
};

// keyName 저장
const saveKeyName = async(keyName, title, linkTitle) => {
    console.log('Saving key name:', keyName, title, linkTitle);
    try{
        const response = await api.post("/history/file", { 
            keyName: keyName,
            title: title,
            linkTitle: linkTitle,
        });

        console.log("Key Name saved successfully: ", response.data);
        return response.data;
    } catch (error) {
        console.error("Error saving key name: ", error);
        if(error.response){
            console.error('Server responded with status code:', error.response.status);
            console.error('Server responded with:', error.response.data);
        } else if(error.request){
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
        throw error;
    }
};

// S3 파일 삭제
const deleteS3File = async (data) => {
    console.log('Deleting S3 file:', data);
    try{
        const fileTitle = data.fileTitle;
        const response = await api.delete(`/history/file?fileName=${fileTitle}`);
        console.log("Success - deleteS3File: ", response.data);
        return response.data;
    } catch (error) {
        console.error("Error deleting S3 file: ", error);
        if(error.response){
            console.error('Server responded with status code:', error.response.status);
            console.error('Server responded with:', error.response.data);
        } else if(error.request){
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
        throw error;
    }
};

// S3 파일 다운로드
const downS3File = async (data) => {
    console.log('Downloading S3 file:', data);
    try{
        // api 호출
        const fileTitle = data.fileTitle;
        const response = await api.get(`/history/file/download?fileName=${fileTitle}`);
        
        // s3의 presendURL로 파일 다운로드
        if(response.status === 200 && response.data.data.presignedURL){
            console.log("Success - downS3File: ", response ,response.data.data.presignedURL);
            // return response.data.data.presignedURL;

            const presignedURL = response.data.data.presignedURL;
            // window.open(presignedURL, '_blank');
            return presignedURL;

        } else{
            console.error('Failed to get presigned URL:', response.status);
            alert('파일 다운로드 URL을 가져오는데 실패했습니다.');
        }
    
    } catch (error) {
        console.error("Error downloading S3 file: ", error);
        if(error.response){
            console.error('Server responded with status code:', error.response.status);
            console.error('Server responded with:', error.response.data);
        } else if(error.request){
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
        alert("파일 다운로드에 실패했습니다.");
    }
};

// fileTitle 변경
const changeFileTitle = async (oldFileName, newFileName) => {
    console.log('Changing file title:', oldFileName, "->", newFileName);
    try{
        const response = await api.put("/history/file/rename", {
            oldFileName: oldFileName,
            newFileName: newFileName
        });
        console.log("Success - changeFileTitle: ", response.data);
        return response.data;
    } catch (error) {
        console.error("Error changing file title: ", error);
        if(error.response){
            console.error('Server responded with status code:', error.response.status);
            console.error('Server responded with:', error.response.data);
        } else if(error.request){
            console.error('No response received:', error.request);
        } else {
            console.error('Error setting up request:', error.message);
        }
        throw error;
    }
}

export { createPresignedUrl, uploadFileToS3, saveKeyName, deleteS3File, downS3File, changeFileTitle };