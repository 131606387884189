import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import DetailAdd from '../../components/MyCareerDetail/DetailAdd';
import DetailAddEdit from '../../components/MyCareerDetail/DetailAddEdit';
import AddCareerModal from '../../components/Modal/AddCareerModal/AddCareerModal';
import { useParams } from 'react-router-dom';

import Careerbox from '../../components/MyCareerDetail/CareerBox';
import CareerList from '../../components/MyCareerDetail/CareerList';
import SearchBar from '../../components/Mycareer/shareSearchBar';

import { CareerViewSelect } from '../../api/Mycareer/CareerviewSelect';
import { ViewCareerDetail } from '../../api/Mycareer/ViewCareerDetail';
import { CareertextEdit } from '../../api/Mycareer/CareerEdit';
import { trackEvent } from '../../utils/ga4';
import { formatDate } from '../../utils/formateDate';
import CareerDetailDeleteModal from '../../components/Modal/CareerDetailDeleteModal';
import { theme } from '../../constants/theme';

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 30px;
	box-sizing: border-box;

	box-sizing: border-box;
`;

const SearchIcon = styled.svg`
	width: 25px;
	height: 25px;
	flex-shrink: 0;
	fill: var(--gray-02, #707070);
	cursor: pointer;
`;

const CareerBoxContainer = styled.div`
	width: 100%;
	max-width: 820px;
	height: 68px;
	margin-top: 40px;
	display: flex; /* 플렉스 박스를 사용 */
	flex-wrap: nowrap; /* 줄 바꿈을 방지 */
	gap: 10px; /* 박스 간격 */
	overflow-x: auto; /*가로 스크롤 활성화 */
	overflow-y: hidden; /*세로 스크롤 방지 */
	white-space: nowrap; /* 텍스트 줄 바꿈 방지 */
	position: relative; /* 제발*/

	box-sizing: border-box;

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		max-width: 100%;
		width: 100%;
	}
`;

const CareerContentContainer = styled.div`
	/*width: 720px;*/
	width: 100%;
	max-width: 720px;
	height: ${(props) => (props.isEditing ? '175px' : 'auto')}; /* 편집 상태에 따라 높이 변경 원래 auto 대신 88*/
	margin-top: 32px;
	margin-bottom: 28px;

	box-sizing: border-box;
	position: relative; /* 위치를 기준으로 자식 컴포넌트가 확장 */

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		max-width: 100%;
		width: 100%;
		height: auto;
	}
`;

const TitleContainer = styled.div`
	height: 30px;
	width: 100%;
	display: flex; /* 요소를 가로로 배치 */
	justify-content: space-between; /* 양쪽 끝에 요소 배치 */
	align-items: center; /* 세로 가운데 정렬 */
`;

const TitleBox = styled.div`
	height: 30px;
	display: flex; /* 요소를 가로로 배치 */
	gap: 20px;
`;

const IconWrapper = styled.div`
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 30px;
		height: 30px;
	}

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		width: 24px;
		height: 24px;

		svg {
			width: 24px;
			height: 24px;
		}
	}
`;

const Title = styled.div`
	color: #000;

	font-family: Pretendard;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const Date = styled.div`
	height: 15px;
	color: #707070;

	margin-top: 8px;
	margin-bottom: 20px;

	font-family: Pretendard;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Content = styled.div`
	width: 720px;
	height: auto;
	color: ${({ hasSummary }) => (hasSummary ? '#000' : 'var(--gray-02, #707070)')};
	font-family: Pretendard;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration: ${({ hasSummary }) => (hasSummary ? 'none' : 'underline')};
	text-decoration-style: solid;
	text-decoration-skip-ink: none;
	text-decoration-thickness: auto;
	text-underline-offset: auto;
	text-underline-position: from-font;
`;

const Line = styled.div`
	width: 100%;
	max-width: 800px;
	height: 6px;
	margin-bottom: 2px; /*추가*/
	background: var(--gray-03, #d9d9d9);

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		max-width: 100%;
		width: 100%;
	}
`;

const CareerListBox = styled.div`
	wideh: 100%;
	max-width: 800px;
	height: auto; /* 원래 560px */
	/* overflow-y: auto; 삭제 */
	overflow-x: hidden;

	/*box-sizing: border-box;
	border: 1px solid black;*/

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		max-width: 100%;
		width: 100%;
	}
`;

const CareerPlus = styled.button`
	max-width: 720px;
	width: 100%;
	height: 50px;
	border-radius: 10px;
	background: var(--main-01, #3aaf85);
	border: none;
	color: white;
	cursor: pointer;
	position: sticky; /* fixed → absolute */

	left: 0;
	right: 0;
	margin: auto;

	bottom: ${(props) => (props.isFixed ? '220px' : '30px')}; /* 🚀 기본적으로 30px, 남은 높이 200px 이하일 때는 200px */
	transition: bottom 0.2s ease-in-out; /* 부드러운 이동 효과 뭘까이건*/

	margin-top: 20px;
	margin-bottom: 20px;

	z-index: 10;
	color: #fff;

	text-align: center;
	font-family: Pretendard;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	background: ${(props) => (props.disabled ? 'var(--gray-03, #D9D9D9)' : 'var(--main-01, #3AAF85)')};
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		max-width: 100%;
		width: 100%;
	}
`;

const EditActivityContent = styled.div`
	max-width: 720px;
	width: 100%;
	height: 106px;
	box-sizing: border-box;

	display: flex; /* 가로 배치 */
	justify-content: space-between; /* 양쪽 끝에 배치 */
	align-items: center; /* 세로 가운데 정렬 */

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		width: 100%;
		flex-direction: column;
		align-items: stretch; /* 버튼 너비 100% */
		height: auto;
		gap: 16px;
	}
`;

const Textbox = styled.textarea`
	max-width: 625px;
	width: 100%;
	height: 106px;
	flex-shrink: 0;
	padding: 10px; /* 텍스트 영역 내부 여백 */
	border: none;
	box-sizing: border-box;
	outline: none; /* 파란색 테두리 제거 */

	border-radius: 10px;
	background: #f5f5f5;

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		width: 100%;
	}
`;

const EditBoxContainer = styled.div`
	width: 80px;
	min-height: 106px;
	gap: 6px;
	display: flex;
	flex-direction: column; /* 세로 배치 */
	align-items: center; /* 버튼 가운데 정렬 */

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		width: 100%;
		min-height: auto;
		height: auto;
		flex-direction: row;
		justify-content: space-between;
		gap: 6px;
	}
`;

const CancelButton = styled.button`
	width: 80px;
	height: 40px;
	flex-shrink: 0;
	border-radius: 10px;
	border: 1px solid var(--sub-bu, #77aff2);

	color: var(--sub-bu, #77aff2);
	text-align: center;
	font-family: Pretendard;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		flex-basis: calc(50% - 3px);
		height: 50px;
	}
`;

const EditButton = styled.button`
	width: 80px;
	height: 60px;
	flex-shrink: 0;
	border-radius: 10px;
	background: var(--main-01, #3aaf85);
	border: none;

	color: #fff;

	text-align: center;
	font-family: Pretendard;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		flex-basis: calc(50% - 3px);
		height: 50px;
	}
`;

const PageContainer = styled.div`
	display: flex;
	flex-direction: column; /* 위에서 아래로 배치 */
	align-items: center; /* 필요하면 가운데 정렬 */
	width: 100%; /* 전체 너비 */
`;

const NoContents = styled.div`
	max-width: 600px;
	width: 100%;
	height: 300px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	color: #707070;
	font-family: Pretendard;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-align: center;
	position: relative;

	@media (max-width: ${(props) => props.theme.breakpoints.md}) {
		max-width: 100%;
		width: 100%;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	width: 100%; /* 부모 컨테이너 전체 너비 */
`;

const EditTag = styled.div`
	width: 65px;
	height: 25px;
	flex-shrink: 0;
	border-radius: 10px;
	background: var(--gray-06, #f5f5f5);

	margin-left: auto; /* ✅ 오른쪽 정렬 */
	color: var(--gray-02, #707070);
	text-align: center;
	font-family: Pretendard;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

const categoryToColorMap = {
	동아리: '#FCC400',
	대외활동: '#77AFF2',
	공모전대회: '#BB7AEF',
	프로젝트: '#78D333',
	경력: '#FA7C79',
	교육: '#F99538',
	기타: '#707070',
	default: '#707070',
};

const NameTag = styled.div`
	display: inline-flex;
	height: 20px;
	padding: 2px 16px;
	justify-content: center;
	align-items: center;
	gap: 10px;
	flex-shrink: 0;
	border-radius: 10px;
	background-color: ${(props) => props.bgColor || categoryToColorMap['default']};
	color: white;
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	text-align: center;
	font-family: Pretendard;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export default function MycareerDetail() {
	const location = useLocation();
	const { careerId, category } = location.state || {};
	const [details, setDetails] = useState(null);
	const [careerList, setCareerList] = useState([]);
	const [selectedCareer, setSelectedCareer] = useState({ id: careerId || null, type: category || null });
	const [isEditing, setIsEditing] = useState(false); // 편집 상태 추가
	const [isAdding, setIsAdding] = useState(false); // 상태 추가  const [editingDetailId, setEditingDetailId] = useState(null); // 현재 DetailAddEdit 상태인 detailId
	const [editingDetailId, setEditingDetailId] = useState(null); // 현재 DetailAddEdit 상태인 detailId
	const [isModalOpen, setIsModalOpen] = useState(false); // 수정 모달 상태 관리
	const [modalData, setModalData] = useState(null); // 모달에 전달할 데이터
	const [isAnyEditing, setIsAnyEditing] = useState(false); //편집 상태를 확인
	const [isSearchOpen, setIsSearchOpen] = useState(false); // 검색창 상태 추가
	const [isFixed, setIsFixed] = useState(false);

	/* 커리어박스 드래그 기능 추가 */
	const careerBoxRef = useRef(null);
	let isDragging = false;
	let startX, scrollLeft;

	const handleMouseDown = (e) => {
		isDragging = true;
		startX = e.pageX - careerBoxRef.current.offsetLeft;
		scrollLeft = careerBoxRef.current.scrollLeft;
		careerBoxRef.current.style.cursor = 'grabbing';
	};

	const handleMouseMove = (e) => {
		if (!isDragging) return;
		e.preventDefault();
		const x = e.pageX - careerBoxRef.current.offsetLeft;
		const walk = (x - startX) * 2; // 드래그 속도 조절
		careerBoxRef.current.scrollLeft = scrollLeft - walk;
	};

	const handleMouseUp = () => {
		isDragging = false;
		careerBoxRef.current.style.cursor = 'grab';
	};
	/*추가 완 */

	useEffect(() => {
		const handleScroll = () => {
			const scrollY = window.scrollY;
			const viewportHeight = window.innerHeight;
			const documentHeight = document.documentElement.scrollHeight;
			const remainingHeight = documentHeight - (scrollY + viewportHeight);

			// 남은 높이가 200px 이하일 때 푸터 위로 고정
			setIsFixed(remainingHeight <= 220);
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	const categoryToTypeMap = {
		대외활동: 'activity',
		동아리: 'circle',
		프로젝트: 'project',
		교육: 'edu',
		공모전대회: 'competition',
		경력: 'employment',
		기타: 'etc',
	};

	const fetchCareerDetails = async (id, type) => {
		try {
			// 한글 타입을 영어 타입으로 변환
			const convertedType = categoryToTypeMap[type] || type;

			const response = await ViewCareerDetail(id, convertedType);
			console.log('가져온 Career Details:', response.data); // 데이터 확인

			// startDate -> startdate로 변환
			const formattedData = {
				...response.data,
				startdate: response.data.startdate || response.data.startDate, // startDate가 있으면 startdate로 변환
				endDate: response.data.endDate || response.data.enddate, // enddate도 일관성 유지
			};

			setDetails(formattedData);
		} catch (error) {
			console.error('Error fetching career details:', error);
		}
	};

	useEffect(() => {
		if (careerId && category) {
			const type = categoryToTypeMap[category]; // 항상 영어로 변환
			if (type) {
				fetchCareerDetails(careerId, type);
			} else {
				console.error(`Invalid category: ${category}`);
			}
		}
	}, [careerId, category]);

	useEffect(() => {
		const fetchAllCareers = async () => {
			try {
				const response = await CareerViewSelect('all');
				if (Array.isArray(response.data)) {
					const formattedData = response.data.map((career) => ({
						...career,
						startdate: career.startdate || career.startDate, // startDate가 있으면 startdate로 변환
					}));
					setCareerList(formattedData);
				}
			} catch (error) {
				console.error('Error fetching all careers:', error);
			}
		};

		fetchAllCareers();
	}, []);

	const handleAddButtonClick = () => {
		setIsAdding(true); // DetailAdd 표시
	};

	const handleCancelAdd = async () => {
		setIsAdding(false); // DetailAdd 숨기기
		await fetchCareerDetails(careerId, categoryToTypeMap[category]); // 데이터 새로고침
	};

	const handleSaveAdd = async () => {
		setIsAdding(false); // DetailAdd 숨기기
		await fetchCareerDetails(careerId, categoryToTypeMap[category]); // 데이터 새로고침
	};

	const handleCareerBoxClick = (id, type) => {
		if (isEditing) {
			setIsEditing(false); // 편집 모드 종료
		}

		setSelectedCareer({ id, type });
		setIsAdding(false);
	};

	useEffect(() => {
		if (selectedCareer.id && selectedCareer.type) {
			fetchCareerDetails(selectedCareer.id, selectedCareer.type);
		}
	}, [selectedCareer]);

	const handleEditClick = () => {
		setIsEditing(true); // 편집 모드로 변경
	};

	const handleSaveClick = async () => {
		try {
			await CareertextEdit(
				careerId, // 현재 활동 ID
				details?.category?.categoryEnName, // 카테고리 이름
				details?.summary || '', // 빈 문자열도 저장 가능하게 수정
			);

			alert('활동 내역이 성공적으로 저장되었습니다.');
			setIsEditing(false); // 편집 모드 종료

			// 수정 후 바로 데이터 새로고침
			await fetchCareerDetails(careerId, selectedCareer.type);
		} catch (error) {
			alert('활동 내역 저장에 실패했습니다.');
		}
	};

	const handleCancelClick = () => {
		setIsEditing(false); // 편집 모드 종료
	};

	const handleCloseEdit = async () => {
		setEditingDetailId(null); // DetailAddEdit 닫기
		const convertedType = categoryToTypeMap[selectedCareer.type] || selectedCareer.type;
		await fetchCareerDetails(careerId, selectedCareer.type);
	};

	const openModal = () => {
		// 모달 열기 + 데이터 설정, 데이터 다보내기
		setModalData({ ...details }); // 전체 details 데이터를 modalData로 설정
		setIsModalOpen(true);
		console.log('Generated initialData for AddCareerModal:', modalData);
	};

	const closeModal = () => {
		setIsModalOpen(false); // 모달 닫기
	};

	return (
		<Layout
			title={
				<Container>
					<span>내 커리어</span>
					{isSearchOpen ? (
						<SearchBar onClose={() => setIsSearchOpen(false)} /> // 검색바 표시
					) : (
						<SearchIcon
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 25 25"
							fill="none"
							onClick={() => setIsSearchOpen(true)} // 클릭 시 검색바 표시
						>
							<path
								d="M22.5852 23.8578L14.6307 16.3683C13.9205 16.9033 13.1037 17.3268 12.1804 17.6389C11.2571 17.9509 10.2746 18.107 9.23295 18.107C6.65246 18.107 4.46875 17.2657 2.68182 15.5833C0.893939 13.8999 0 11.8435 0 9.41384C0 6.98422 0.893939 4.92774 2.68182 3.24439C4.46875 1.56193 6.65246 0.720703 9.23295 0.720703C11.8134 0.720703 13.9976 1.56193 15.7855 3.24439C17.5724 4.92774 18.4659 6.98422 18.4659 9.41384C18.4659 10.3946 18.3002 11.3196 17.9687 12.189C17.6373 13.0583 17.1875 13.8273 16.6193 14.496L24.6094 22.0189C24.8698 22.2641 25 22.565 25 22.9216C25 23.2783 24.858 23.5903 24.5739 23.8578C24.3134 24.103 23.982 24.2256 23.5795 24.2256C23.1771 24.2256 22.8456 24.103 22.5852 23.8578ZM9.23295 15.4322C11.0085 15.4322 12.518 14.8473 13.7614 13.6775C15.0038 12.5068 15.625 11.0856 15.625 9.41384C15.625 7.74208 15.0038 6.32087 13.7614 5.15019C12.518 3.98041 11.0085 3.39551 9.23295 3.39551C7.45739 3.39551 5.94792 3.98041 4.70454 5.15019C3.46212 6.32087 2.84091 7.74208 2.84091 9.41384C2.84091 11.0856 3.46212 12.5068 4.70454 13.6775C5.94792 14.8473 7.45739 15.4322 9.23295 15.4322Z"
								fill="#707070"
							/>
						</SearchIcon>
					)}
				</Container>
			}>
			<PageContainer>
				<CareerBoxContainer
					ref={careerBoxRef}
					onMouseDown={handleMouseDown}
					onMouseLeave={handleMouseUp}
					onMouseUp={handleMouseUp}
					onMouseMove={handleMouseMove}>
					{careerList.map((career) => (
						<Careerbox
							key={career.id}
							id={career.id}
							startdate={career.startdate}
							enddate={career.enddate}
							unknown={career.unknown}
							careerName={career.name}
							category={career.category.categoryKoName}
							selected={career.id === selectedCareer.id && career.category.categoryKoName === selectedCareer.type}
							onClick={() => handleCareerBoxClick(career.id, career.category.categoryKoName)}
						/>
					))}
				</CareerBoxContainer>
				<CareerContentContainer isEditing={isEditing}>
					<TitleContainer>
						<TitleBox>
							<Title>{details?.alias || '제목 없음'}</Title>
							<NameTag bgColor={categoryToColorMap[details?.category?.categoryKoName] || categoryToColorMap['default']}>
								{details?.name || 'No Name'}
							</NameTag>
						</TitleBox>

						<IconWrapper onClick={openModal}>
							<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
								<path
									d="M0 23.7509V30H6.24913L24.6799 11.5692L18.4308 5.32009L0 23.7509ZM29.5126 6.73656C30.1625 6.08665 30.1625 5.0368 29.5126 4.38689L25.6131 0.487432C24.9632 -0.162477 23.9133 -0.162477 23.2634 0.487432L20.2139 3.53701L26.463 9.78614L29.5126 6.73656Z"
									fill="#707070"
								/>
							</svg>
						</IconWrapper>
					</TitleContainer>
					<Date>{formatDate(details?.startdate, details?.endDate, details?.unknown)}</Date>
					{isEditing ? (
						<EditActivityContent>
							<Textbox
								value={details?.summary || ''} // defaultValue → value 변경
								onChange={(e) => {
									const text = e.target.value;
									if (text.length <= 500) {
										setDetails({ ...details, summary: text });
									}
								}}
								maxLength={500} // HTML 기본 maxLength 추가
							/>
							<EditBoxContainer>
								<CancelButton onClick={handleCancelClick}>취소</CancelButton>
								<EditButton
									onClick={() => {
										trackEvent('btn_click', {
											category: 'career',
											detail: 'career_summary',
											action_type: 'confirm',
											label: '확인',
										});
										handleSaveClick();
									}}>
									저장
								</EditButton>
							</EditBoxContainer>
						</EditActivityContent>
					) : (
						<ContentWrapper>
							{details?.summary ? (
								<>
									<Content hasSummary>{details.summary}</Content>
									<EditTag onClick={handleEditClick}>수정</EditTag>
								</>
							) : (
								<Content
									style={{ textDecoration: 'underline', cursor: 'pointer' }}
									onClick={() => {
										trackEvent('add_conrifm', {
											category: 'mycareer',
											detail: 'career_summary',
											action_type: 'add',
											label: '활동 내역 추가',
										});
										handleEditClick();
									}}>
									활동내역을 작성해주세요.
								</Content>
							)}
						</ContentWrapper>
					)}
				</CareerContentContainer>
				<Line></Line>
				<CareerListBox>
					{isAdding && ( //항상 맨 위에 DetailAdd를 추가
						<DetailAdd
							onCancel={handleCancelAdd}
							onSave={handleSaveAdd}
							careerId={careerId}
							careerType={categoryToTypeMap[category]}
						/>
					)}

					{details?.detailList?.length > 0 ? ( // 활동 내역이 존재하면 리스트 보여주기
						<>
							{details.detailList.map((detail) =>
								editingDetailId === detail.detailId ? (
									<DetailAddEdit
										key={detail.detailId}
										initialTitle={detail.title}
										initialDate={detail.startDate}
										initialEndDate={detail.endDate} // endDate 추가
										initialUnknown={detail.unknown}
										initialContents={detail.content}
										initialTags={detail.detailTag || []}
										careerId={careerId}
										detailId={detail.detailId}
										onClose={handleCloseEdit}
										onUpdate={() => {
											const convertedType = categoryToTypeMap[selectedCareer.type] || selectedCareer.type;
											fetchCareerDetails(careerId, convertedType);
										}}
									/>
								) : (
									<CareerList
										key={detail.detailId}
										title={detail.title}
										date={
											detail.startDate === detail.endDate || !detail.endDate
												? detail.startDate
												: `${detail.startDate} ~ ${detail.endDate}`
										}
										contents={detail.content}
										detailTag={detail.detailTag || []}
										careerId={careerId}
										detailId={detail.detailId}
										categoryEnName={details?.category?.categoryEnName}
										onClose={handleCloseEdit}
										onUpdate={() => {
											const convertedType = categoryToTypeMap[selectedCareer.type] || selectedCareer.type;
											fetchCareerDetails(careerId, convertedType);
										}}
										onEditClick={() => handleEditClick(detail.detailId)}
									/>
								),
							)}
						</>
					) : (
						// 활동이 없을 때만 NoContents 표시 (DetailAdd 중복 방지)
						<NoContents>
							등록된 활동 기록이 없습니다. <br />
							아래 버튼을 눌러 활동 기록을 추가해주세요!
						</NoContents>
					)}
				</CareerListBox>

				<CareerPlus
					onClick={() => {
						trackEvent('add_click', {
							category: 'mycareer',
							detail: 'career_detail',
							action_type: 'add',
							label: '활동 기록 추가',
						});
						window.scrollTo({ top: 0, behavior: 'smooth' });
						handleAddButtonClick();
					}}
					disabled={editingDetailId !== null}>
					활동 기록 추가
				</CareerPlus>
				{isModalOpen && modalData && (
					<AddCareerModal onClose={closeModal} data={modalData} mode="edit" initialData={modalData} />
				)}
			</PageContainer>
		</Layout>
	);
}
